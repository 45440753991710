export async function sha256(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder('utf-8').encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

export const delay = (time) => {
  return new Promise((resolve, reject) => {
    if (isNaN(time)) {
      return reject(new Error('Parameter must be a number'));
    }
    setTimeout(resolve, time);
  });
};

// TODO: Remove the FeatureFlag check with C1C-11968
const checkGovCloudRegion = () => {
  const isGovCloudDeploymentTarget = ['govcloud-prod', 'govcloud-staging'].includes(
    DEPLOYMENT_TARGET
  );
  const hasGovCloudFeatureFlag = JSON.parse(
    localStorage.getItem('C1C_GOV_CLOUD_ENABLED') || 'false'
  );
  return isGovCloudDeploymentTarget || hasGovCloudFeatureFlag;
};
export const isGovCloudRegion = checkGovCloudRegion();
const checkAlpha = () => {
  const isAlphaDeploymentTarget = ['alpha'].includes(DEPLOYMENT_TARGET);
  return isAlphaDeploymentTarget;
};
export const isAlphaRegion = checkAlpha();
const checkDev = () => {
  const isDevDeploymentTarget = ['dev'].includes(DEPLOYMENT_TARGET);
  return isDevDeploymentTarget;
};
export const isDevRegion = checkDev();
const checkStaging = () => {
  const isStagingDeploymentTarget = ['stage'].includes(DEPLOYMENT_TARGET);
  return isStagingDeploymentTarget;
};
export const isStagingRegion = checkStaging();

export const hasProdLogsEnabled = JSON.parse(
  localStorage.getItem('C1C_PROD_LOGS_ENABLED') || 'false'
);
