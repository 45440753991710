import { AwsRum } from 'aws-rum-web';

const APPLICATION_VERSION = '1.0.0';
const APPLICATION_REGION = 'us-east-1';

const commonRUMConfigAttributes = {
  allowCookies: true,
  enableXRay: false,
  sessionSampleRate: 1,
  telemetries: ['errors', 'performance', 'http'],
  endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
};

const CONFIGURATION = {
  alpha: {
    ...commonRUMConfigAttributes,
    guestRoleArn:
      'arn:aws:iam::000568857918:role/sls-cloudfront-cdn-alpha-C1RUMClientRole-U46VBKINRR08',
    identityPoolId: `${APPLICATION_REGION}:6efe5a83-f721-4d3e-9381-30f958965135`,
  },
  stage: {
    ...commonRUMConfigAttributes,
    guestRoleArn:
      'arn:aws:iam::031419218300:role/sls-cloudfront-cdn-staging-C1RUMClientRole-HE4MVRI6I1JP',
    identityPoolId: `${APPLICATION_REGION}:535aec55-2662-4734-ac7a-9a9474ad6d60`,
  },
  prod: {
    ...commonRUMConfigAttributes,
    guestRoleArn:
      'arn:aws:iam::147995105371:role/sls-cloudfront-cdn-prod-C1RUMClientRole-1EMNXM0JVCJZZ',
    identityPoolId: `${APPLICATION_REGION}:18bf235c-f95a-4963-810f-b60333ca8c21`,
  },
  'govcloud-staging': {
    ...commonRUMConfigAttributes,
    // TODO Add After GovCloud Deployment
  },
  'govcloud-prod': {
    ...commonRUMConfigAttributes,
    // TODO Add After GovCloud Deployment
  },
}[DEPLOYMENT_TARGET];

const APPLICATION_ID = {
  alpha: 'a6fd3c40-4dc6-462e-b60f-4a934d18bb21',
  stage: '63854620-ae4f-46f7-aef3-9da8f19c69fa',
  prod: 'c04a0f37-2022-42ef-93a0-31bdc116a91f',
  'govcloud-staging': '',
  'govcloud-prod': '',
}[DEPLOYMENT_TARGET];

const initAwsRum = () => {
  try {
    return new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, CONFIGURATION);
  } catch (error) {
    console.error('Unable to initialize AWS CloudWatch RUM');
  }
};

export default initAwsRum;
